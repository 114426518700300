<template>
    <div class="MC_headerInner">

        <div class="MC_tableOut">
            <table class=" " :style="$store.state.moduleLoading ? 'min-height: 200px' : ''"
                v-loading="$store.state.moduleLoading" element-loading-background="rgba(0, 0, 0, 0.2)">
                <thead>
                    <tr>
                        <th>{{ $t('平臺名稱') }}</th>
                        <th>{{ $t('操作金額') }}</th>
                        <th>{{ $t('型別') }}</th>
                        <th>{{ $t('日期') }}</th>
                        <th>{{ $t('狀態') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in re_record" :key="`re_record${index}`">
                        
                        <td class="td__type">{{ item.name }}</td>
                        
                        <td class="td__amount">
                            <span class="currency">
                                <div class="currency__balances-wrap">
                                    <div class="currency__balances">{{ item.amount }}</div>
                                </div>
                            </span>
                        </td>
                        <td class="td__information">
                            <div >{{ item.type==3?$t('平臺轉游戲'):$t('遊戲轉平臺') }}</div>
                        </td>
                        <td class="td__date">
                            <span class="datetime">{{ item.time }}</span>
                        </td>
                        <td class="td__status">
                            <div class="td__status-wrapper datetime">
                                <div v-if="item.status == 1" class="text-yellow">{{ $t('未透過') }}</div>
                                <div v-if="item.status == 2" class="text-red">{{ $t('未透過') }}</div>
                                <div v-if="item.status == 3 || item.status == 4" class="text-green">{{ $t('已透過') }}
                                </div>
                            </div>
                        </td>
                        
                    </tr>
                </tbody>
            </table>

            <div class="container-class" v-if="re_record.length > 0">
                <el-pagination background :small="isMobileFlag" :pager-count="5" layout="prev, pager, next"
                    :current-page.sync="record_page" :page-size="10" @current-change="changePageNum"
                    :total="re_listTotal"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { third_balance_log } from "@/api/user";
export default {
    props: {
        recodeType: {
            type: Number,
            default: 2
        }
    },
    data() {
        return {
            options: [],
            showType: 1,
            selectValue: 1,
            start_time: "",
            end_time: "",
            dateVal: "",
            re_record: [],
            record_page: 1,
            limit: 10,
            re_listTotal: 0
        }
    },
    mounted() {
        this.moduleType()
    },
    methods: {
        async fetchPlatfromLog() {
            this.$store.commit("$vuexSetModuleLoadingState", true);
            let params = {
                page: this.record_page,
                limit: 5
            };
            const response = await third_balance_log(params)
            if (response.data.code == 1) {
                this.re_record = response.data.data;
                this.re_listTotal = response.data.total;
            }
            this.$store.commit("$vuexSetModuleLoadingState", false);
        },
        moduleType() {
            this.re_record = [];
            this.fetchPlatfromLog()
        },
        selectChange(e) {
            this.record_page = 1;
            if (this.recodeType == 2) {
                for (let i in this.options) {
                    if (this.options[i].value == e) {
                        this.showType = this.options[i].value;
                        this.selectValue = this.options[i].value;
                    }
                }
            }
            this.moduleType()
        },


        changePageNum(e) {
            this.record_page = e;
            this.re_record = [];
            this.moduleType();
        },

        dateChange(dateVal) {
            if (dateVal) {
                this.start_time = this.dateVal[0];
                this.end_time = this.dateVal[1];
            } else {
                this.start_time = "";
                this.end_time = "";
            }
            this.moduleType();
        },
    }
}

</script>

<style lang="less" scoped>
.MC_headerInner {
    width: 778px;
    margin: 0 auto;
    height: 458px;
    background-color: #fff;
    border: 1px solid #d4d4d4;
    padding-top: 6px;

    @media screen and (max-width:768px) {
        width: 100vw;
        border: none;
        padding-top: 0;
        background-color: #efefef;
    }
}

.MC_header {
    background-color: #d4d4d4;
    height: 40px;
    padding: 0 10px;
    line-height: 38px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width:768px) {
        background-color: #fff;
    }
}

.MC_headerR {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

}

.filter-date {
    display: flex;
    align-items: center;
    max-width: 230px;
}

::v-deep .el-input__inner {
    height: 34px;
}

::v-deep .el-range-input {
    background: transparent !important;
    color: #666 !important;
}

::v-deep input[readonly="readonly"] {
    background-color: #fff;
}

::v-deep .el-select-dropdown {
    position: absolute;
    z-index: 1001;
    border: 1px solid #161f2c;
    border-radius: 4px;
    background: #fff !important;
    color: #ffffff;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    box-sizing: border-box;
    margin: 5px 0;
}

::v-deep .el-select-dropdown__item.selected {
    color: #fff;
    pointer-events: none;
    background-image: linear-gradient(180deg, #309476, #2b8569) !important;
    background-color: transparent !important;
    border-radius: 8px;
}

.MC_tableOut {
    display: table;
    position: relative;
    width: 756px;
    height: 418px;
    margin: 10px;

    @media screen and (max-width:768px) {
        width: 100%;
        margin: 0;
    }
}


.text-yellow {
    color: #ffb636 !important;
}

.filter-select {
    width: 107px !important;
}

table tbody td {
  position: relative;
  vertical-align: top;
  padding: 18px 8px;
  color: #000;
}
</style>