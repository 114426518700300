<template>
    <div class="xw_page_container">
        <div class="member_header">
            <div class="member_title_name " v-if="$store.state.windowWidth > 768">
                {{ $t('會員中心') }}
            </div>
            <div class="search_item" v-if="$store.state.windowWidth > 768">
                <ul class="member_tab_list">
                    <li @click="tabActive = index" v-for="(item, index) in tabList" :key="index" class="member_tab_item"
                        :class="{ active: tabActive === index }">
                        {{ $t(item) }}
                    </li>
                </ul>
            </div>

            <div class="bg_header" v-else>
                <div class="header_content">
                    <button @click="goBack" type="button" class="btn_back"></button>
                    <span>{{ $t(tabList[tabActive]) }}</span>
                    <span class="icon_inforMoney" v-if="$store.state.userInfo">{{ this.$helper.get("rule").currency.code }}{{
                        numFormat($store.state.userInfo.balance)}}</span><button @click="goBack" type="button"
                        class="btn_back"></button>
                </div>
            </div>
        </div>

        <div class="member_content">
            <deposit v-if="tabActive == tabEnum.DEPOSIT" />
            <xwWithdrawal v-if="tabActive == tabEnum.WITHDRAWAL" />
            <transactionHistory :recodeType="tabActive"
                v-if="tabActive == tabEnum.TXRECORDS || tabActive == tabEnum.ACRRECORDS" />
            <xwInformation v-if="tabActive == tabEnum.USERINFO" />
            <transferAccounts v-if="tabActive == tabEnum.TRANSFER" />
            <transactionDetails v-if="tabActive == tabEnum.FLOWRECORDS" />
        </div>
        <xwTabbar v-if="$store.state.windowWidth <= 768" />

    </div>
</template>

<script>
import deposit from "./xwComponents/deposit.vue";
import xwWithdrawal from "./xwComponents/xwWithdrawal.vue";
import transactionHistory from "./xwComponents/transactionHistory.vue";
import xwInformation from "./xwComponents/xwInformation.vue";
import transferAccounts from "./xwComponents/transferAccounts.vue";
import transactionDetails from "./xwComponents/transactionDetails.vue";
import xwTabbar from "@/components/xwTabbar.vue";

export default {
    components: {
        deposit,
        xwWithdrawal,
        transferAccounts,
        transactionHistory,
        xwInformation,
        transactionDetails,
        xwTabbar
    },
    data() {
        return {
            tabEnum: {
                DEPOSIT: 0,
                WITHDRAWAL: 1,
                TRANSFER:2,
                TXRECORDS: 3,
                ACRRECORDS: 4,
                FLOWRECORDS:5,
                USERINFO: 6
                
            },
            tabActive: 0,
            tabList: ["充值專區", "提現專區", '平臺轉帳',"交易記錄", "賬款記錄","流水明細", "變更資料"]
            // ,
        }
    },
    mounted() {
        this.tabActive = this.tabEnum[this.$route.params.pageName] || 0
    },
    watch:{
        "$route.params":function (v){
            this.tabActive = this.tabEnum[this.$route.params.pageName] || 0
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    }
}
</script>

<style lang="less" scoped>
.xw_page_container {
    width: 100vw;
    min-height: var(--vh);
    background-color: #ebebeb;
}

.member_header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #3a9b7c;

    @media (max-width:768px) {
        position: fixed;
        top: 0;
        left: 0;
        height: 40px;
        background-color: #3f9eff;
        z-index: 10;
    }
}

.member_title_name {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    margin-left: 34px;
}

.search_item {
    margin-left: 0;
    height: 0;
    position: absolute;
    top: 24px;
    left: 179px;
    width: max-content;
}

.member_tab_list {
    list-style: none;
    display: flex;
    align-items: center;
    user-select: none;
}

.member_tab_item {
    min-width: auto;
    padding: 0 17px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    width: max-content;
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
}

.member_tab_item.active {
    background-color: #ebebeb !important;
    color: #3a9b7c;
}

.bg_header {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99;
}

.header_content{
    position: relative;
    color: #fff;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-weight: bold;
}

.btn_back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    width: 40px;
    display: block;
    background: url('../../assets/images/xw/btn_back.svg') no-repeat center;
    background-size: auto 40%;
}

.member_content {
    width: fit-content;
    margin: 0 auto;
    padding: 15px;
    height: calc(var(--vh) - 80px);
    overflow: auto;
    @media (max-width:768px) {
        padding: 40px 0;
    }
}

.icon_inforMoney {
    position: absolute;
    top: 0;
    right: 16px;
    height: 100%;
    color: #feea11;
}
</style>