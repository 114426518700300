<template>
    <div class="MC_headerInner">
        <div class="MC_header">
            <div class="MC_headerR">

                <div class="filter-date">
                    <el-date-picker format="MM-dd-yyyy" value-format="yyyy-MM-dd" v-model="dateVal" @change="dateChange"
                        type="daterange" range-separator="—" :start-placeholder="$t('開始日期')"
                        :end-placeholder="$t('結束日期')"></el-date-picker>
                </div>
            </div>
        </div>

        <div class="MC_tableOut">
            <table class=" " :style="$store.state.moduleLoading ? 'min-height: 200px' : ''"
                v-loading="$store.state.moduleLoading" element-loading-background="rgba(0, 0, 0, 0.2)">
                <thead>
                    <tr>
                        <th rowspan="2">日期</th>
                        <th>點數</th>
                        <th>必須流水</th>
                        <th>當下流水</th>
                    </tr>
                    <tr>
                        <th>優惠點數</th>
                        <th>當下勝败</th>
                        <th>剩餘流水</th>
                    </tr>
                </thead>
                <tbody v-for="(item, index) in re_record" :key="`re_record${index}`">
                    <tr>
                        <td class="td__type"  rowspan="2">{{ item.date }}</td>
                        <td class="td__type">
                            {{ item.recharge_amount }}
                        </td>
                        <td class="td__type">
                            {{ item.total_water }}
                        </td>
                        <td class="td__type">
                            {{ item.finish_water }}
                        </td>
                    </tr>
                    <tr>
                        <td class="td__type">
                            {{ item.activity_award }}
                        </td>
                        <td class="td__type">
                            {{ item.win_loss }}
                        </td>
                        <td class="td__type">
                            {{ item.surplus_water }}
                        </td>
                    </tr>
                </tbody>
            </table>


            <!-- <div class="container-class" v-if="re_record.length > 0">
                <el-pagination background :small="isMobileFlag" :pager-count="5" layout="prev, pager, next"
                    :current-page.sync="record_page" :page-size="10" @current-change="changePageNum"
                    :total="re_listTotal"></el-pagination>
            </div> -->
        </div>
    </div>
</template>

<script>
import { waterStatistics } from "@/api/user";
export default {
    props: {
        recodeType: {
            type: Number,
            default: 2
        }
    },
    data() {
        return {
            options: [],
            showType: 1,
            start_time: "",
            end_time: "",
            dateVal: "",
            re_record: [],
            record_page: 1,
            limit: 5,
            re_listTotal: 0
        }
    },

    mounted() {
        this.moduleType()
    },
    methods: {
        moduleType() {
            // this.record_page = 1
            this.re_record = [];
            this.getBalanceRecord()
        },

        async getBalanceRecord() {
            this.$store.commit("$vuexSetModuleLoadingState", true);
            let param = {};
            param = {
                page: this.record_page,
                limit: 5,
                start_date: this.start_time||null,
                end_date: this.end_time||null,
                type: this.selectValue,
            };
            await waterStatistics(param).then(res => {
                if (res && res.data.code == 1) {
                    console.log(res);
                    this.re_record = res.data.data;
                    this.re_listTotal = res.data.total;
                }
                this.$store.commit("$vuexSetModuleLoadingState", false);
            });
        },


        changePageNum(e) {
            this.record_page = e;
            this.re_record = [];
            this.moduleType();
        },

        dateChange(dateVal) {
            if (dateVal) {
                this.start_time = this.dateVal[0];
                this.end_time = this.dateVal[1];
            } else {
                this.start_time = "";
                this.end_time = "";
            }
            this.moduleType();
        },
    }
}

</script>

<style lang="less" scoped>
.MC_headerInner {
    width: 778px;
    margin: 0 auto;
    min-height: 458px;
    background-color: #efefef;
    border: 1px solid #d4d4d4;

    @media screen and (max-width:768px) {
        width: 100vw;
        border: none;
    }
}

.MC_header {
    background-color: #d4d4d4;
    height: 40px;
    padding: 0 10px;
    line-height: 38px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width:768px) {
        background-color: #fff;
    }
}

.MC_headerR {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

}

.filter-date {
    display: flex;
    align-items: center;
    max-width: 230px;
}

::v-deep .el-input__inner {
    height: 34px;
}

::v-deep .el-range-input {
    background: transparent !important;
    color: #666 !important;
}

::v-deep input[readonly="readonly"] {
    background-color: #fff;
}

::v-deep .el-select-dropdown {
    position: absolute;
    z-index: 1001;
    border: 1px solid #161f2c;
    border-radius: 4px;
    background: #fff !important;
    color: #ffffff;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    box-sizing: border-box;
    margin: 5px 0;
}

::v-deep .el-select-dropdown__item.selected {
    color: #fff;
    pointer-events: none;
    background-image: linear-gradient(180deg, #309476, #2b8569) !important;
    background-color: transparent !important;
    border-radius: 8px;
}

.MC_tableOut {
    display: table;
    position: relative;
    width: 756px;
    height: 418px;
    margin: 10px;

    @media screen and (max-width:768px) {
        width: 100%;
        margin: 10px 0;
    }
}


.text-yellow {
    color: #ffb636 !important;
}

.filter-select {
    width: 107px !important;
}

table thead th{
    border: 1px solid #b6b1b1;
    vertical-align: middle;
    text-align: center;
}
table tbody td {
    position: relative;
    vertical-align: top;
    padding: 8px;
    color: #000;
    vertical-align: middle;
    text-align: center;
    height: 42px;
}


</style>